import * as React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby";
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const MembersFujikawa = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Programmer：ふじもん｜メンバー紹介｜About us｜株式会社スタートアッププラス" />

      <div className="block xl:flex justify-between">

        <div id="pageTitle" className="relative xl:fixed w-full xl:w-48 h-auto xl:h-full text-white bg-aboutusPage bg-no-repeat bg-center bg-cover  z-20 ">
          <h1 className="font-Copperplate text-center text-2xl sm:text-3xl py-10">
            About us
          </h1>
          <ul className="w-full text-center pb-5">
            <li className='mb-5'>
              <Link to="/aboutus-data" className=''>
                データで見るSUP
              </Link>
            </li>
            <li className='mb-5'>
              <Link to="/aboutus-environment" className=''>
                働く環境
              </Link>
            </li>
            <li className='mb-5'>
              <Link to="/aboutus-members" className=''>
                メンバー紹介
              </Link>
            </li>
          </ul>
        </div>

        <div id="main" className="static xl:ml-48 w-full">
          <div className=" w-11/12 max-w-[1000px] mx-auto py-10 sm:py-10">

            <section id="memberProfile" className="mb-20">
              <div className="flex flex-wrap justify-center sm:justify-start">

                <div className="flex w-full md:w-1/2 p-3">
                  <div className="w-1/3">
                    <StaticImage src="../../images/fujimon.png" className="w-full h-auto max-w-[160px]"/>
                  </div>
                  <div className="w-2/3 px-4">
                    <h3 className=" pl-2 my-3 border-l-4 border-key-sand">
                      開発チーム<br />プログラマー
                    </h3>
                    <h2 className="text-xl pl-3 mb-3 font-medium">ふじもん</h2>
                    <p className="text-xs pl-3">入社：2018年8月<br />出身：東京都</p>
                  </div>
                </div>

                <div className=" w-full md:w-1/2 p-3">
                  <div className="w-full h-full p-3 border border-key-sand rounded-xl">
                    <p className="pb-2 list-none flex items-center border-b border-dashed border-key-sand">
                      <StaticImage src="../../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                      現在の仕事内容は？
                    </p>
                    <p className="p-2">
                    開発・保守がメインで、現在は業務システム・API・自社スマホアプリの仕事をしています。
                    </p>
                  </div>
                </div>

                <div className=" w-full md:w-1/2 p-3">
                  <div className="w-full h-full p-3 border border-key-sand rounded-xl">
                    <p className="pb-2 list-none flex items-center border-b border-dashed border-key-sand">
                      <StaticImage src="../../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                      印象に残った仕事は？
                    </p>
                    <p className="p-2">
                    iOSアプリのスケジュール管理とAPI開発を同時にやった仕事が一番印象強いです。社外のエンジニアの方と話すことがそれまであまりなかったのと、1人でサーバサイドを担当することがなかったので、色々なことを考えるきっかけになりました。
                    </p>
                  </div>
                </div>

                <div className=" w-full md:w-1/2 p-3">
                  <div className="w-full h-full p-3 border border-key-sand rounded-xl">
                    <p className="pb-2 list-none flex items-center border-b border-dashed border-key-sand">
                      <StaticImage src="../../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                      仕事のやりがいを感じるときは？
                    </p>
                    <p className="p-2">
                    自分の技術・知識が、誰かを助けることができたときにやりがいを感じます。
                    </p>
                  </div>
                </div>

                <div className=" w-full md:w-1/2 p-3">
                  <div className="w-full h-full p-3 border border-key-sand rounded-xl">
                    <p className="pb-2 list-none flex items-center border-b border-dashed border-key-sand">
                      <StaticImage src="../../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                      職場の雰囲気は？
                    </p>
                    <p className="p-2">
                    雰囲気はよく落ち着いていますが、過干渉はないので安心できます。
                    </p>
                  </div>
                </div>

                <div className=" w-full md:w-1/2 p-3">
                  <div className="w-full h-full p-3 border border-key-sand rounded-xl">
                    <p className="pb-2 list-none flex items-center border-b border-dashed border-key-sand">
                      <StaticImage src="../../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                      スタートアッププラスの良いところは？
                    </p>
                    <p className="p-2">
                    やり方に干渉されないところ。わからないことは聞けば答えてくれる人はいるので、その安心感も良いところかなと思います。                    </p>
                  </div>
                </div>

                <div className=" w-full md:w-1/2 p-3">
                  <div className="w-full h-full p-3 border border-key-sand rounded-xl">
                    <p className="pb-2 list-none flex items-center border-b border-dashed border-key-sand">
                      <StaticImage src="../../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                      前職について教えてください。
                    </p>
                    <p className="p-2">
                    前職は事務職をやっていました。エンジニア業務は未経験でした。
                    </p>
                  </div>
                </div>

                <div className=" w-full md:w-1/2 p-3">
                  <div className="w-full h-full p-3 border border-key-sand rounded-xl">
                    <p className="pb-2 list-none flex items-center border-b border-dashed border-key-sand">
                      <StaticImage src="../../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                      会社を選ぶ際にこだわったことは？
                    </p>
                    <p className="p-2">
                    未経験だったのでそこまで選り好みはできませんでしたが、面接時の誠実さは見ていたと思います。
                    </p>
                  </div>
                </div>

                <div className=" w-full md:w-1/2 p-3">
                  <div className="w-full h-full p-3 border border-key-sand rounded-xl">
                    <p className="pb-2 list-none flex items-center border-b border-dashed border-key-sand">
                      <StaticImage src="../../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                      入社を決めた要因を教えてください。
                    </p>
                    <p className="p-2">
                    面接だけでなく、適性試験でしっかりと面接者を見ようという意思が感じられたためです。
                    </p>
                  </div>
                </div>

                <div className=" w-full md:w-1/2 p-3">
                  <div className="w-full h-full p-3 border border-key-sand rounded-xl">
                    <p className="pb-2 list-none flex items-center border-b border-dashed border-key-sand">
                      <StaticImage src="../../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                      入社して自分が一番成長したところは？
                    </p>
                    <p className="p-2">
                    仕事の進め方を以前より自分で考えるようになりました。仕事のやり方について干渉されないので、それが結果的に成長につながっていると思います。
                    </p>
                  </div>
                </div>

                <div className=" w-full md:w-1/2 p-3">
                  <div className="w-full h-full p-3 border border-key-sand rounded-xl">
                    <p className="pb-2 list-none flex items-center border-b border-dashed border-key-sand">
                      <StaticImage src="../../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                      仕事でこれからチャレンジしてみたいことは？
                    </p>
                    <p className="p-2">
                    入社された方のメンターのような仕事ができればと思っています。
                    </p>
                  </div>
                </div>

                <div className=" w-full md:w-1/2 p-3">
                  <div className="w-full h-full p-3 border border-key-sand rounded-xl">
                    <p className="pb-2 list-none flex items-center border-b border-dashed border-key-sand">
                      <StaticImage src="../../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                      入社して驚いた事はありますか？
                    </p>
                    <p className="p-2">
                    繰り返しになりますが、干渉されなさすぎて最初は驚いたというか戸惑いました。
                    </p>
                  </div>
                </div>

                <div className=" w-full md:w-1/2 p-3">
                  <div className="w-full h-full p-3 border border-key-sand rounded-xl">
                    <p className="pb-2 list-none flex items-center border-b border-dashed border-key-sand">
                      <StaticImage src="../../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                      仕事とプライベートの両立はできていますか？
                    </p>
                    <p className="p-2">
                    ゲームしない日はほぼないです。
                    </p>
                  </div>
                </div>

                <div className=" w-full md:w-1/2 p-3">
                  <div className="w-full h-full p-3 border border-key-sand rounded-xl">
                    <p className="pb-2 list-none flex items-center border-b border-dashed border-key-sand">
                      <StaticImage src="../../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                      「あってよかった」と感じる社内制度・福利厚生は？
                    </p>
                    <p className="p-2">
                    資格取得支援制度はあってよかったです。IT関連の認定資格には受験料が高いものも多いので。
                    </p>
                  </div>
                </div>

                <div className=" w-full md:w-1/2 p-3">
                  <div className="w-full h-full p-3 border border-key-sand rounded-xl">
                    <p className="pb-2 list-none flex items-center border-b border-dashed border-key-sand">
                      <StaticImage src="../../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                      どんな人に入社してきてほしい？
                    </p>
                    <p className="p-2">
                    特にないです。会社にはいろんな人がいた方が良いと思うので。
                    </p>
                  </div>
                </div>
              </div>

            </section>

            <Link to="/aboutus-members" class=" m-5 flex justify-center border border-neutral-800 px-4 py-3 transition duration-300 ease-in-out hover:bg-key-sand">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-left-short" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"></path>
              </svg>
              <span className=" ">メンバーの一覧に戻る</span>
            </Link>

          </div>
        </div>
      </div>

    </Layout>

    
  )
}



export default MembersFujikawa

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

